<template>
  <v-dialog v-model="deleteViewing.dialog" persistent max-width="400">
    <v-card>
      <v-card-title class="headline">Archive Viewing</v-card-title>
      <v-card-text
        >Are you sure you wish to archive
        {{ deleteViewing.viewing.id }}?</v-card-text
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
        <v-btn
          color="success"
          depressed
          :loading="deleteViewing.loading"
          @click="saveDelete"
          >Yes</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    deletefromProperty: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      deleteViewing: {
        dialog: false,
        viewing: {},
        loading: false,
      },
    };
  },

  methods: {
    openDelete(viewing) {
      this.deleteViewing.viewing = viewing;
      this.deleteViewing.dialog = true;
    },

    resetDelete() {
      this.deleteViewing.dialog = false;
      this.deleteViewing.viewing = {};
      this.deleteViewing.loading = false;
    },

    saveDelete() {
      this.deleteViewing.loading = true;

      let payload = {
        appId: this.$route.params.id,
        viewingId: this.deleteViewing.viewing.id,
      };

      if (this.deletefromProperty) {
        payload.fromProperty = true;
      }

      this.$store
        .dispatch("melrose/propertiesStore/deleteViewing", payload)
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteViewing.loading = false;
        });
    },
  },
};
</script>
