<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Viewings</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Viewings"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-select
            label="Not Sold/ Sold"
            :items="soldSelect"
            v-model="soldSearch"
            item-text="name"
            item-value="value"
            hide-details
            :clearable="false"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  v-on="on"
                  @click="$refs.viewingDialog.open()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Viewing</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  light
                  v-on="on"
                  :to="{
                    name: 'module-melrose-properties-viewings-archived',
                  }"
                >
                  <v-icon small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>View Archived Viewings</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="viewings"
        class="mt-4"
        show-expand
        no-data-text="No Viewings found"
      >
        <template v-slot:item.property="{ item }">
          <router-link
            :to="{
              name: 'module-melrose-properties-individual',
              params: { propertyId: item.property.id },
            }"
            v-html="item.property.address.short"
          ></router-link>
        </template>
        <template v-slot:item.enquiry="{ item }">
          <span v-if="item.enquiry">
            {{ item.enquiry.customer.full_name }}
          </span>
        </template>
        <template v-slot:item.feedback="{ item }">
          <v-chip label small color="success" v-if="item.feedback">Yes</v-chip>
          <v-chip label small color="error" v-else>No</v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                class="mr-2"
                @click="$refs.viewingDialog.open(item)"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="$refs.deleteViewingDialog.openDelete(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pt-4 pb-4">
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Notes</th>
                  <td v-html="item.notes" class="pt-4 pb-4"></td>
                </tr>
                <tr>
                  <th>Performed By?</th>
                  <td v-html="item.viewing_performed_by" class="pt-4 pb-4"></td>
                </tr>
              </tbody>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </v-container>
    <viewing-dialog ref="viewingDialog" :editingFromProperty="false" />
    <delete-viewing-dialog ref="deleteViewingDialog" />
  </div>
</template>

<script>
import ViewingDialog from "./components/ViewingDialog.vue";
import DeleteViewingDialog from "./components/DeleteViewingDialog.vue";

export default {
  components: {
    ViewingDialog,
    DeleteViewingDialog,
  },

  data() {
    return {
      searchTerm: "",
      soldSearch: "Not-Sold",
      soldSelect: [
        { name: "Not Sold", value: "Not-Sold" },
        { name: "Sold", value: "Sold" },
      ],
      breadcrumbs: [
        {
          text: "Properties",
          disabled: false,
          exact: true,
          to: { name: "module-melrose-properties-all" },
        },
        {
          text: "Viewings",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Viewing Date", value: "formatted_dates.viewing_date" },
        { text: "Property", value: "property" },
        { text: "Customer", value: "enquiry" },
        { text: "Status", value: "status" },
        { text: "Follow Up Date", value: "formatted_dates.follow_up_date" },
        { text: "Feedback Received?", value: "feedback" },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "right",
          width: "120px",
        },
        { text: "", value: "data-table-expand" },
      ],
    };
  },

  computed: {
    viewings() {
      let viewings = this.$store.getters["melrose/propertiesStore/viewings"];

      if (this.soldSearch == "Not-Sold") {
        viewings = viewings.filter(function (item) {
          return item.property.status != "Sold STC";
        });
      }

      if (this.soldSearch == "Sold") {
        viewings = viewings.filter(function (item) {
          return item.property.status == "Sold STC";
        });
      }

      if (this.searchTerm !== "") {
        viewings = viewings.filter((v) => {
          const property = v.property.address.short.toLowerCase();
          const customer = v.customer.full_name.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();

          return property.includes(searchTerm) || customer.includes(searchTerm);
        });
      }

      return viewings;
    },

    properties() {
      return this.$store.getters["melrose/propertiesStore/all"];
    },
  },
};
</script>
